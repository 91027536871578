import { Button, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { ComponentProps, FC } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: 'white',
    borderRadius: theme.spacing(0.5),
    border: `solid ${theme.palette.primary.main} 1px`,
    height: theme.spacing(4.5),
    padding: '0 30px',
    '& span': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      background: theme.palette.primary.main,
      '& span': {
        color: 'white',
      },
    },
  },
}))

const WhiteButton: FC<ComponentProps<any>> = props => {
  const { children } = props

  const classes = useStyles()

  return (
    <Button variant="contained" className={classes.root}>
      {children}
    </Button>
  )
}

export default WhiteButton
