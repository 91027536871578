import { graphql, useStaticQuery } from 'gatsby'
import { LegalStatusData } from '../useAllLegalStatus'

export interface LegalStatusButtonData {
  id: string
  button_txt_fr: string
  url: string
}

export interface LegalStatusQuestionData {
  id: string
  question_txt_fr: string
  button: LegalStatusButtonData[]
}

export interface LegalStatusChoiceData {
  id: string
  big_title_fr: string
  description_fr: string
  question: LegalStatusQuestionData
  arian_fil_fr: string
  legal_status_lists: LegalStatusData[]
}

export default (): LegalStatusChoiceData[] => {
  const data = useStaticQuery(graphql`
    {
      allStrapiLegalStatusChoices {
        nodes {
          id
          big_title_fr
          description_fr
          arian_fil_fr
          question {
            id
            question_txt_fr
            button {
              id
              button_txt_fr
              url
            }
          }
          legal_status_lists {
            id
            STATUS
            priority
            Status {
              id
              legal_status_mg
              legal_status_fr
              legal_status_en
              acronym_legal_status_mg
              acronym_legal_status_fr
              acronym_legal_status_en
              associate_mg
              associate_fr
              associate_en
              project_definition_mg
              project_definition_fr
              project_definition_en
              note_mg
              note_fr
              note_en
              articles {
                id
              }
            }
          }
        }
      }
    }
  `)

  return data.allStrapiLegalStatusChoices.nodes
}
