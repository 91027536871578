import { Box, Button, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { LegalStatusQuestionData } from '../../../../hooks/legalStatusChoice/useLegalStatusChoice'
import { RootState } from '../../../../redux/store'
import { getInternationalizedValue } from '../../../../utils/common'
import WhiteButton from '../../../customButtons/whiteButton'
import TextMarkdown from '../../../customMarkdown/textMarkdown'

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonContainer: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
}))

interface LegalHeaderProps {
  title: string
  description: string
  question?: LegalStatusQuestionData
  selectedStatusID: string
  handleSelect(id: string): () => void
}

const LegalHeader: FC<LegalHeaderProps> = props => {
  const { title, description, question, selectedStatusID, handleSelect } = props
  const classes = useStyles()

  const { language } = useSelector((state: RootState) => state.app)
  const questionTxt = props.question
    ? getInternationalizedValue(question, 'question_txt', language)
    : ''

  return (
    <Box>
      <Box className={classes.titleContainer}>
        <Box>
          <Typography variant="h3">{title}</Typography>
        </Box>
        <Box>
          {selectedStatusID !== '1' ? (
            <Box marginTop={1.5} marginLeft={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSelect('1')}
              >
                Recommencer
              </Button>
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box paddingTop={1}>
        <Typography variant="body2">
          <TextMarkdown text={description} />
        </Typography>
      </Box>
      <Box paddingY={2}>
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>
          {questionTxt}
        </Typography>
      </Box>
      <Box className={classes.buttonContainer}>
        {question
          ? question.button.map(b => {
              const text = getInternationalizedValue(b, 'button_txt', language)
              return (
                <Box
                  key={`question-button-${b.id}`}
                  marginRight={1}
                  onClick={handleSelect(b.url)}
                  marginY={1}
                >
                  <WhiteButton>{text}</WhiteButton>
                </Box>
              )
            })
          : null}
      </Box>
    </Box>
  )
}

export default LegalHeader
