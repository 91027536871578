import { Box, Button, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import TextMarkdown from '../components/customMarkdown/textMarkdown'
import LegalCard from '../components/pages/legalStatusChoices/legalCard'
import LegalHeader from '../components/pages/legalStatusChoices/legalHeader'
import SEO from '../components/seo'
import { PrefixEnum } from '../enum/common'
import useLegalStatusChoice from '../hooks/legalStatusChoice/useLegalStatusChoice'
import { RootState } from '../redux/store'
import { addPrefix, getInternationalizedValue } from '../utils/common'
import {
  getLegalStatusById,
  sortLegalStatusByPriority,
} from '../utils/legalStatus'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // marginTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8),
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  container: {
    // marginTop: theme.spacing(12),
    background:
      'linear-gradient(180deg, #C9FFEF 0%, rgba(255, 255, 255, 0) 100%), #FFF',
    borderRadius: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: `0px ${theme.spacing(8)}px`,
    },
    [theme.breakpoints.down('md')]: {
      padding: `0px ${theme.spacing(4)}px`,
    },
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
    marginBottom: theme.spacing(4),
  },
  cardsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  cards: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}))

const LegalStatusChoice: FC = () => {
  const classes = useStyles()

  const { language } = useSelector((state: RootState) => state.app)
  const data = useLegalStatusChoice()

  const [selectedStatusID, setSelectedStatusID] = useState('1')
  const currentLegalStatus = getLegalStatusById(
    addPrefix(selectedStatusID, PrefixEnum.LEGAL_STATUSES),
    data,
  )

  // console.log('!!', currentLegalStatus)

  const title = currentLegalStatus
    ? getInternationalizedValue(currentLegalStatus, 'big_title', language)
    : ''
  const description = currentLegalStatus
    ? getInternationalizedValue(currentLegalStatus, 'description', language)
    : ''

  const handleSelectLegalStatus = (id: string) => () => {
    setSelectedStatusID(id)
  }

  const arianFil = currentLegalStatus
    ? getInternationalizedValue(currentLegalStatus, 'arian_fil', language)
    : ''

  const sortedLegalStatus = currentLegalStatus
    ? sortLegalStatusByPriority(currentLegalStatus.legal_status_lists)
    : []

  return (
    <Box className={classes.root}>
      <SEO title="Legal Status Choice" />
      <Box className={classes.container}>
        <LegalHeader
          title={title}
          description={description}
          selectedStatusID={selectedStatusID}
          question={
            (currentLegalStatus && currentLegalStatus.question) || undefined
          }
          handleSelect={handleSelectLegalStatus}
        />

        <Box>
          <Typography variant="body2">
            <TextMarkdown text={arianFil} isCustomBold={true} />
          </Typography>
        </Box>

        <Box className={classes.cardsContainer}>
          <Box className={classes.cards}>
            {sortedLegalStatus.map(s => (
              <LegalCard key={`legalCard-${s.id}`} legalStatus={s} />
            ))}
          </Box>
        </Box>

        {selectedStatusID !== '1' ? (
          <Box marginTop={4} display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSelectLegalStatus('1')}
            >
              Recommencer
            </Button>
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}

export default LegalStatusChoice
