import { Divider, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'
import PersonIcon from '@material-ui/icons/PermIdentity'
import BusinessIcon from '@material-ui/icons/BusinessCenter'
import React, { FC } from 'react'
import SimpleLink from '../../../customLinks/simpleLink'
import { LegalStatusData } from '../../../../hooks/useAllLegalStatus'
import { RootState } from '../../../../redux/store'
import { useSelector } from 'react-redux'
import {
  getArticlePath,
  getInternationalizedValue,
} from '../../../../utils/common'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: 400,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      width: 290,
    },
    // marginRight: theme.spacing(3),
    margin: theme.spacing(1),
    borderRadius: theme.spacing(1),
    position: 'relative',
  },
  contentContainer: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3.5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
    marginBottom: theme.spacing(6),
  },
  linkContainer: {
    textAlign: 'center',
    textTransform: 'uppercase',
    height: theme.spacing(6),
    width: '100%',
    bottom: 0,
    position: 'absolute',
  },
}))

interface LegalCardProps {
  legalStatus: LegalStatusData
}

const LegalCard: FC<LegalCardProps> = props => {
  const {
    legalStatus: { STATUS, Status },
  } = props
  const { container, contentContainer, linkContainer } = useStyles()

  const { language } = useSelector((state: RootState) => state.app)
  const legalStatusDefinition = getInternationalizedValue(
    Status,
    'legal_status',
    language,
  )
  const associate = getInternationalizedValue(Status, 'associate', language)
  const projectDefinition = getInternationalizedValue(
    Status,
    'project_definition',
    language,
  )
  const note = getInternationalizedValue(Status, 'note', language)

  const { articles } = Status
  const createPath = articles && articles.id ? getArticlePath(articles.id) : '#'

  return (
    <Paper elevation={8} className={container}>
      <Box className={contentContainer}>
        <Box>
          <Typography variant="h3">{STATUS}</Typography>
          <Box marginY={2}>
            <Typography variant="body1">{legalStatusDefinition}</Typography>
          </Box>
        </Box>
        <Box paddingX={1}>
          <Box display="flex" alignItems="center" marginBottom={0.5}>
            <PersonIcon color="primary" />
            <Box marginLeft={1.5}>
              <Typography variant="body1">{associate}</Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" marginY={0.5}>
            <BusinessIcon color="primary" />
            <Box marginLeft={1.5}>
              <Typography variant="body1">{projectDefinition}</Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" marginY={0.5}>
            <DoneIcon color="primary" />
            <Box marginLeft={1.5}>
              <Typography variant="body1">{note}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={linkContainer}>
        <Divider />
        <Box paddingY={1}>
          <Typography variant="h6">
            <SimpleLink
              isContent={true}
              isBold={true}
              item={{ label: `Créer une ${STATUS}`, path: createPath }}
            />
          </Typography>
        </Box>
      </Box>
    </Paper>
  )
}

export default LegalCard
